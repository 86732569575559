/* @import url('@pmndrs/branding/styles.css'); */

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: 'Inter var', sans-serif;
  color: white;
  background: #101010;
  user-select: none;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.bg {
  background: #101010;
}

.fullscreen.notready {
  color: #606060;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #808080;
  padding: 40px;
  pointer-events: none;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  font-size: 10px;
  line-height: 1.5em;
}

.menu.left {
  justify-content: flex-start;
}

.menu.right {
  justify-content: flex-end;
}

.menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.menu > div b {
  font-weight: 600;
  color: #b0b0b0;
}

.fullscreen.ready > div {
  cursor: pointer;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

.menu a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.menu a:hover {
  text-decoration: underline;
  color: inherit;
}

.footer {
  z-index: 10;
  /* position: relative; */
  position: absolute;
  bottom: 0;
  right: 200px;
  height: 100px;
  width: 32px;
}

.site-wrapper {
  padding: 0 120px;
}

.link {
  width: 40px;
}
.link img {
  width: 32px;
}

.footer .grid {
  top: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer .grid .l-50, .r-50 {
  flex-basis: 50%;
  max-width: 50%;
}

.r-50 {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
}
h2 {
  font-size: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing:.2em;
  font-family: 'Inter Bold', Helvetica, sans-serif;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.link img {
    opacity: .4;
    transition: all .2s ease-out;

}

.link img:hover {
    opacity: .8;
    transition: all .4s ease-in;
}

.link img {
  width: 34px;
}

ul{
  width: fit-content;
}
